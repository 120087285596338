@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap');

$grey: rgba(0, 0, 0, 0.54);
$greyLight: rgb(224, 224, 224);
$greyUltraLight: rgb(189, 189, 189);
$greyDark: rgba(0, 0, 0, 0.87);
$red: rgb(183, 28, 28);
$pink: rgb(245, 0, 87);
$background: rgb(245, 245, 245);
$orangeLight: rgba(255, 74, 0, 0.54);
$green: rgb(76, 175, 80);

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

html {
  height: 100%;
}

body {
  margin: 0;
  background-color: $background;
  padding: 0;
  font-size: calc(10px + 0.3vw);
  line-height: calc(12px + 0.3vw);
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  min-height: 100%;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#root {
  width: 100%;
  min-height: 100%;
  height: 100%;
  .app {
    width: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media all and (max-width: 1920px) {
      zoom: 100%;
    }
    @media all and (max-width: 1120px) {
      zoom: 90%;
    }
    @media all and (max-width: 1020px) {
      zoom: 75%;
    }
    @media all and (max-width: 850px) {
      zoom: 60%;
    }
  }
}

:root {
  --amplify-primary-color: rgb(33, 150, 243);
  --amplify-primary-tint: rgb(33, 150, 233);
  --amplify-primary-shade: rgb(33, 150, 253);
}

.header {
  //padding: 0rem 2rem;
  z-index: 5000;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  //min-height: 3.5rem;
  h3 {
    font-weight: 500;
    font-size: 1.25rem;
  }
  img {
    margin-right: 1rem;
    margin-left: 1.25rem;
  }
  .left {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .right {
    align-self: flex-end;
  }
}

.login {
  display: flex;
  min-height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  flex: auto;

  .loginForm {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: 560px;
    min-height: 423px;
    margin-top: auto;

    h1 {
      font-size: 2.12rem;
      margin-bottom: 0.5rem;
      letter-spacing: 0.25px;
      line-height: 2.6rem;
      margin-top: 1.3rem;
    }

    h2 {
      font-size: 0.8rem;
      color: $grey;
      letter-spacing: 0.1px;
      line-height: 1.3rem;
      font-weight: 500;
      margin-bottom: 1.5rem;
    }

    form {
      display: flex;
      flex-direction: column;
      padding-top: 1.5rem;
      border-top: 1px solid $greyLight;

      .MuiTextField-root {
        min-width: 420px;
        margin-bottom: 2.6rem;
      }

      h6 {
        font-weight: 500;
        font-size: 0.75rem;
        letter-spacing: 0.83px;
        line-height: 1.9rem;
        text-transform: uppercase;
        color: $orangeLight;
        margin-bottom: 1.5rem;
      }

      .success {
        .MuiInput-underline:after {
          border-bottom-color: $green;
        }
        svg {
          color: $green;
        }
      }

      button {
        margin-bottom: 4.3rem;
      }
    }
  }

  .footer {
    text-transform: uppercase;
    font-size: 0.8rem;
    color: #ccc;
    margin: 1rem 0rem 1rem;
    text-align: center;
    margin-top: auto;
  }
}

.list-scroll {
  max-height: 70vh;
  overflow: auto;
  position: relative;

  &.filter-open {
    max-height: calc(70vh - 220px);
  }
}

.runs-actions {
  z-index: 999;
  padding: 20px 10px;
}
.MuiButtonBase-root {
  &.reprocess {
    width: 100%;
    margin-bottom: 10px;
  }
}

.layout {
  width: 100%;
  display: flex;
  min-height: calc(100vh - 4rem);

  .leftCol {
    width: 400px;
    padding: 1rem 0rem;
    background: #fff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;

    > div {
      position: relative;
      z-index: 1;
    }

    .logo {
      margin: 1rem auto 2rem;
      width: 120px;
      height: auto;
    }
    .MuiToolbar-regular {
      display: none;
    }
    .MuiPaper-elevation2 {
      box-shadow: none !important;
      margin: 0 1rem;
    }
    table.table {
      width: calc(100% - 1rem);
      margin: 0 0.5rem;
      tr {
        &.outdated {
          background-color: rgb(255, 238, 238);
        }
        td {
          border-top: 1px solid $greyLight;
        }
        th,
        td {
          text-align: left;
          padding: 0.3rem 0.4rem;
          padding: 0.5rem 0.4rem;
          color: $greyDark;
          vertical-align: middle;
          line-height: 1.3rem;
        }
        /*           th {
            span {
              font-weight: 500;
              font-size: 0.875rem;
              letter-spacing: 0.07px;
              color: $greyDark;
            }
            &:first-child {
              width: 5% !important;
              text-align: left !important;
            }
            &:nth-child(2) {
              width: 80% !important;
            }
            &:nth-child(3) {
              width: 10% !important;
            }
          } */
      }
    }
  }

  .rightCol {
    width: calc(100% - 400px - 2rem);
    background: #fff;
    margin: 1rem;
    padding: 1rem 2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .MuiTabs-scroller {
      border-bottom: 1px solid $greyLight;

      .MuiButtonBase-root {
        span {
          font-size: 0.87rem;
          font-weight: 500;
          letter-spacing: 0.28px;
        }
      }
    }

    .footer {
      text-transform: uppercase;
      font-size: 0.8rem;
      color: #ccc;
      margin: 1rem 0rem 1rem;
      text-align: center;
    }
  }
}

.runs,
.groups,
.plot,
.viewRuns,
.viewPlot {
  padding: 2rem 0;
  width: calc(100vw - 400px - 6rem);
}

.runs {
  .headline {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1.68rem;

    h2 {
      font-size: 2.12rem;
      font-weight: 400;
    }
  }
  .content {
    display: flex;
    flex-direction: column;
    margin-bottom: 3.75rem;

    .center {
      width: 100%;
      display: flex;
      flex-direction: row;

      .MuiFormControl-root {
        margin-bottom: 1.875rem;
      }
      .leftSide2 {
        display: flex;
        flex-direction: column;
        width: 75%;
        margin-left: 1.875rem;
        margin-right: 0;
      }

      .leftSide {
        display: flex;
        flex-direction: column;
        width: 75%;
        margin-right: 1.875rem;

        .two,
        .topTwo {
          display: flex;
          .MuiFormControl-root {
            &:first-child {
              margin-right: 1.875rem;
            }
          }
          .topTwoFirst {
            width: 100%;
            margin-right: 1.875rem;
          }
        }
      }
      .rightSide {
        display: flex;
        flex-direction: column;
        width: 25%;
      }
    }

    .select {
      width: 100%;
    }
  }

  .bottom {
    display: flex;
    flex-direction: column;

    h2 {
      font-weight: 500;
      font-size: 1.25rem;
      line-height: 2rem;
    }
    .MuiPaper-elevation2 {
      box-shadow: none;
      margin-bottom: 1.87rem;

      .MuiToolbar-root {
        display: none;
      }

      table {
        tr {
          td {
            &:first-child {
              width: 50px !important;
            }
            &:nth-child(2) {
              //width: 63% !important;
            }
            &:nth-child(3) {
              //width: 18% !important;
            }
          }
        }
      }
    }
    button {
      align-self: flex-end;
    }
  }
}

.groups,
.viewRuns,
.viewPlot {
  h2 {
    font-weight: 500;
    font-size: 1.25rem;
    letter-spacing: 0.7px;
    line-height: 2rem;
    margin-bottom: 1.25rem;
  }

  .headline {
    margin-bottom: 0.5rem;

    .filters {
      font-weight: 500;
      font-size: 0.75rem;
      letter-spacing: 0.83px;
      color: $grey;
      text-transform: uppercase;
      margin-right: 0.6rem;
    }
  }

  .center {
    margin-bottom: 5rem;
  }
  .bottom {
    display: flex;
    flex-direction: column;

    .MuiPaper-root {
      .MuiToolbar-root {
        display: none;
      }
    }

    button {
      align-self: flex-end;
    }
  }

  .MuiPaper-root,
  .table {
    box-shadow: none;
    margin-bottom: 1.875rem;

    .toolbar {
      .MuiToolbar-root {
        padding: 0;

        .MuiInputBase-root {
          border: 1px solid $greyLight;
          border-radius: 4px;
          &::before,
          &::after {
            content: none;
          }
          .MuiInputAdornment-root {
            &.MuiInputAdornment-positionStart {
              color: $grey;
              margin-left: 1rem;
            }
            &.MuiInputAdornment-positionEnd {
              margin-left: 0;
            }
          }
          .MuiInputBase-input {
            color: $grey;
            max-width: 136px;
          }
        }
      }
    }

    table {
      border-top: 1px solid $greyLight;
      thead {
        tr {
          th {
            span {
              font-weight: 500;
              font-size: 0.875rem;
              letter-spacing: 0.07px;
              color: $greyDark;
            }
            &:first-child {
              width: 5% !important;
              text-align: left !important;
            }
            &:nth-child(2) {
              width: 30% !important;
            }
            &:nth-child(3) {
              width: 20% !important;
            }
            &:last-child {
              width: 5% !important;
            }
          }
        }
      }
      tbody {
        tr {
          td {
            button {
              padding: 0;
            }
          }
        }
      }
    }
  }
}

.line {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .line {
    button + button {
      margin-left: 1.25rem;
    }
  }

  + .line {
    margin-top: 1.25rem;
  }
}

.classicButton.MuiButton-outlined {
  background-color: white;
  &.big {
    padding: 0.31rem 2.68rem;
  }
  &.red {
    color: $red;
    border-color: $red;
  }
  &.default {
    color: $greyUltraLight;
    border-color: $greyUltraLight;
  }
  &.pink {
    color: $pink;
    border-color: $pink;
  }
}

.classicButton.MuiButton-contained {
  &.red {
    color: white;
    background-color: $red;
  }
}

.search {
  width: 100%;
  position: relative;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.15);
  border: 1px solid $greyLight;
  color: $grey;
  .searchIcon {
    height: 100%;
    display: flex;
    padding: 0px 16px;
    position: absolute;
    align-items: center;
    pointer-events: none;
    justify-content: center;
  }
  .searchInput {
    padding: 8px 8px 8px 0px;
    transition: width 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    padding-left: calc(1em + 32px);
    max-height: 36px;
    input {
      color: $grey;
    }
  }
}

.modal {
  z-index: 10000 !important;
  .MuiBackdrop-root {
    background-color: rgba(255, 255, 255, 0.8);
  }
  .MuiPaper-root.MuiDialog-paper {
    min-width: 350px;
    min-height: 219px;
    display: flex;
    align-items: center;
    justify-content: center;

    p {
      font-weight: 500;
      font-size: 1rem;
      letter-spacing: 1.11px;
      line-height: 1.9rem;
      color: $grey;
      text-transform: uppercase;
      text-align: center;
      margin-bottom: 2.5rem;
    }

    button {
      &:first-child {
        margin-right: 2rem;
      }
    }
  }
}

.dataTableWrap {
  width: 100%;
  overflow: scroll;
  .dataTable {
    width: 100%;
    th,
    td {
      padding: 0.2rem 0.4rem;
      text-align: center;
      vertical-align: top;
      sup {
        vertical-align: super;
      }
      strong {
        font-weight: bold;
      }
    }
  }

  tr {
    &.last-in-run {
      td {
        //border-bottom: 1px solid $greyLight;
      }
    }
  }
}
.MuiAlert-action {
  align-items: flex-start !important;
}
.MuiTableSortLabel-icon {
  display: none;
}

.autocompleteWrap {
  margin-top: -1.2rem;
  position: relative;
  z-index: 6;
  padding-left: 2rem;
  margin-right: 1.475rem;
}

.viewPlot {
  .plotVars {
    display: flex;
    .quarter {
      width: 23%;
      margin-right: 2%;
      .select {
        width: 100%;
      }
    }
  }
  .plotItself {
    margin: 2rem 0rem;
  }
}

.filterButton {
  cursor: pointer;
  width: calc(100% - 2rem);
  margin-left: 0.5rem;
  display: flex;
  justify-content: space-between;
  vertical-align: middle;
  padding: 0.5rem 0.5rem;
  &:hover {
    background-color: #eeeeee;
  }
  span {
    height: 100%;
    display: flex;
    vertical-align: middle;
    line-height: 27px;
  }
}
.DateRangePickerInput {
  padding: 0rem 1.8rem;
  .DateInput_input {
    font-size: 16px;
    line-height: 20px;
  }
}
.CalendarMonth_caption {
  padding-bottom: 50px !important;
}
.CalendarMonth_table {
  td {
    vertical-align: middle;
  }
  .CalendarDay__selected_span {
    background: rgb(70, 187, 283);
    border: 1px double rgb(63, 180, 273);
    color: #fff;
  }
  .CalendarDay__selected,
  .CalendarDay__selected:active,
  .CalendarDay__selected:hover {
    background: rgb(33, 150, 243);
    border: 1px double rgb(33, 150, 243);
    color: #fff;
  }
}
.DayPickerKeyboardShortcuts_buttonReset {
  display: none;
}
/* .DayPickerKeyboardShortcuts_show__bottomRight::before {
  border-right: 33px solid rgb(33, 150, 243) !important;
} */
